import logo from './logo.svg';
import './App.css';

import React from 'react';

import { Amplify } from 'aws-amplify';

import { Auth } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';

import awsExports from './aws-exports';
Amplify.configure(awsExports);

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        <p>
          Test worked!!!
          Second Test!!!
        </p>
        <p>
          Third Test!!!
        </p>
        <p>
          4th Test!!!
        </p>
        <button
          onClick={() => Auth.federatedSignIn()}
        >
          Federated Sign In
        </button>
        </header>
        
    </div>
  );
}

export default App;
